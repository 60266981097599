import Modal from "react-bootstrap/Modal";
import FormContainer from "./FormContainer/FormContainer";
import "./FormContainer/Form.css";
function ModalShow({ show, setShow, apply }) {
  return (
    <Modal
      show={show}
      onHide={setShow}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Body className="form-background">
        <FormContainer apply={apply} setShow={setShow} />
      </Modal.Body>
    </Modal>
  );
}
export default ModalShow;
