import React, { useState } from "react";
import Select from "./Select";
import RoundedButton from "../Buttons/RoundedButton";
import Input from "./Input";

function FormContainer({ apply, contact, setShow }) {
  const newUser = {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
    currentOpening: [
      "Business Development Head",
      "Tender Executive",
      "Operation and Procurement Manager",
      "Machine Learning",
    ],
  };

  const [values, setValues] = useState({ newUser });

  const [user, setUser] = useState(newUser);
  const [validations, setValidations] = useState({
    name: "",
    last_name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
    currentOpening: [
      "Business Development Head",
      "Tender Executive",
      "Operation and Procurement Manager",
      "Machine Learning",
    ],
  });

  const validateAll = () => {
    const { name, last_name, email, country, message, currentOpening } = values;
    const validations = {
      name: "",
      last_name: "",
      email: "",
      country: "",
      message: "",
      currentOpening: "",
    };
    let isValid = true;

    if (!name) {
      validations.name = "Name is required";
      isValid = false;
    }

    if ((name && name.length < 3) || name.length > 50) {
      validations.name = "Name must contain between 3 and 50 characters";
      isValid = false;
    }

    if (!email) {
      validations.email = "Email is required";
      isValid = false;
    }

    if (email && !/\S+@\S+\.\S+/.test(email)) {
      validations.email = "Email format must be as example@mail.com";
      isValid = false;
    }

    if (!country) {
      validations.gender = "Country is required";
      isValid = false;
    }
    if (!message) {
      validations.gender = "Message is required";
      isValid = false;
    }
    if (!currentOpening) {
      validations.gender = "Fill current opening";
      isValid = false;
    }

    if (!isValid) {
      setValidations(validations);
    }

    return isValid;
  };

  const validateOne = (e) => {
    const { name } = e.target;
    const value = values[name];
    let message = "";

    if (!value) {
      message = `${name} is required`;
    }

    if (value && name === "name" && (value.length < 3 || value.length > 50)) {
      message = "Name must contain between 3 and 50 characters";
    }

    if (value && name === "email" && !/\S+@\S+\.\S+/.test(value)) {
      message = "Email format must be as example@mail.com";
    }

    setValidations({ ...validations, [name]: message });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValid = validateAll();

    if (!isValid) {
      return false;
    }

    alert(JSON.stringify(values));
  };

  const { name, email, gender } = values;

  const { name: nameVal, email: emailVal, gender: genderVal } = validations;

  function handleInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    setUser({
      ...user,
      [name]: value,
    });
    console.log(user, "user");
  }

  function handleTextArea(e) {
    console.log("Inside handleTextArea");
    let value = e.target.value;
    setUser({
      ...user,
      message: value,
    });
  }

  function handleFormSubmit(e) {
    // e.preventDefault();
    let userData = user;
    fetch(
      "https://qtmbm6vyr6.execute-api.us-east-1.amazonaws.com/prod/tagbin_website_form_api_v2",
      {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((response) => {
      response.json().then((data) => {
        console.log("Successful" + data);
      });
    });
  }

  function optionSelectionHandler(e) {
    let value = e.target.value;
    setUser({
      ...user,
      country: value,
    });
    console.log(user, "user");
  }

  function handleClearForm(e) {
    e.preventDefault();
    setUser({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      currentOpening: [],
    });
  }

  return (
    <form className="container-fluid form" onSubmit={handleFormSubmit}>
      <div className="form-heading">
        <h5>{apply ? "Apply Here" : "Get in touch"}</h5>
        {!contact && (
          <i className="fa fa-times cross-btn" onClick={setShow}></i>
        )}
      </div>
      <div className="one-container" style={contact && { display: "block" }}>
        <Input
          inputType={"text"}
          title={"First Name"}
          name={"First Name"}
          value={user.name}
          placeholder={"Enter your first name"}
          handleChange={handleInput}
          validate={validateOne}
        />
        <Input
          inputType={"text"}
          title={"Last Name"}
          name={"Last Name"}
          value={user.name}
          placeholder={"Enter your last name"}
          handleChange={handleInput}
        />
      </div>
      <div className="one-container" style={contact && { display: "block" }}>
        <Input
          inputType={"email"}
          title={"Email"}
          name={"email"}
          value={user.name}
          placeholder={"Enter your email"}
          handleChange={handleInput}
          validateVal={nameVal}
        />
        <Input
          inputType={"tel"}
          title={"Phone"}
          name={"Phone"}
          value={user.name}
          placeholder={"Enter your phone number"}
          handleChange={handleInput}
        />
      </div>
      {apply && <label className="form-label required">Select Country</label>}
      {apply && (
        <select className="form-control" onChange={optionSelectionHandler}>
          <option value="Afghanistan">Afghanistan</option>
          <option value="Albania">Albania</option>
          <option value="Algeria">Algeria</option>
          <option value="American Samoa">American Samoa</option>
          <option value="Andorra">Andorra</option>
          <option value="Angola">Angola</option>
          <option value="Anguilla">Anguilla</option>
          <option value="Antartica">Antarctica</option>
          <option value="Antigua and Barbuda">Antigua and Barbuda</option>
          <option value="Argentina">Argentina</option>
          <option value="Armenia">Armenia</option>
          <option value="Aruba">Aruba</option>
          <option value="Australia">Australia</option>
          <option value="Austria">Austria</option>
          <option value="Azerbaijan">Azerbaijan</option>
          <option value="Bahamas">Bahamas</option>
          <option value="Bahrain">Bahrain</option>
          <option value="Bangladesh">Bangladesh</option>
          <option value="Barbados">Barbados</option>
          <option value="Belarus">Belarus</option>
          <option value="Belgium">Belgium</option>
          <option value="Belize">Belize</option>
          <option value="Benin">Benin</option>
          <option value="Bermuda">Bermuda</option>
          <option value="Bhutan">Bhutan</option>
          <option value="Bolivia">Bolivia</option>
          <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
          <option value="Botswana">Botswana</option>
          <option value="Bouvet Island">Bouvet Island</option>
          <option value="Brazil">Brazil</option>
          <option value="British Indian Ocean Territory">
            British Indian Ocean Territory
          </option>
          <option value="Brunei Darussalam">Brunei Darussalam</option>
          <option value="Bulgaria">Bulgaria</option>
          <option value="Burkina Faso">Burkina Faso</option>
          <option value="Burundi">Burundi</option>
          <option value="Cambodia">Cambodia</option>
          <option value="Cameroon">Cameroon</option>
          <option value="Canada">Canada</option>
          <option value="Cape Verde">Cape Verde</option>
          <option value="Cayman Islands">Cayman Islands</option>
          <option value="Central African Republic">
            Central African Republic
          </option>
          <option value="Chad">Chad</option>
          <option value="Chile">Chile</option>
          <option value="China">China</option>
          <option value="Christmas Island">Christmas Island</option>
          <option value="Cocos Islands">Cocos (Keeling) Islands</option>
          <option value="Colombia">Colombia</option>
          <option value="Comoros">Comoros</option>
          <option value="Congo">Congo</option>
          <option value="Congo">Congo, the Democratic Republic of the</option>
          <option value="Cook Islands">Cook Islands</option>
          <option value="Costa Rica">Costa Rica</option>
          <option value="Cota D'Ivoire">Cote d'Ivoire</option>
          <option value="Croatia">Croatia (Hrvatska)</option>
          <option value="Cuba">Cuba</option>
          <option value="Cyprus">Cyprus</option>
          <option value="Czech Republic">Czech Republic</option>
          <option value="Denmark">Denmark</option>
          <option value="Djibouti">Djibouti</option>
          <option value="Dominica">Dominica</option>
          <option value="Dominican Republic">Dominican Republic</option>
          <option value="East Timor">East Timor</option>
          <option value="Ecuador">Ecuador</option>
          <option value="Egypt">Egypt</option>
          <option value="El Salvador">El Salvador</option>
          <option value="Equatorial Guinea">Equatorial Guinea</option>
          <option value="Eritrea">Eritrea</option>
          <option value="Estonia">Estonia</option>
          <option value="Ethiopia">Ethiopia</option>
          <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
          <option value="Faroe Islands">Faroe Islands</option>
          <option value="Fiji">Fiji</option>
          <option value="Finland">Finland</option>
          <option value="France">France</option>
          <option value="France Metropolitan">France, Metropolitan</option>
          <option value="French Guiana">French Guiana</option>
          <option value="French Polynesia">French Polynesia</option>
          <option value="French Southern Territories">
            French Southern Territories
          </option>
          <option value="Gabon">Gabon</option>
          <option value="Gambia">Gambia</option>
          <option value="Georgia">Georgia</option>
          <option value="Germany">Germany</option>
          <option value="Ghana">Ghana</option>
          <option value="Gibraltar">Gibraltar</option>
          <option value="Greece">Greece</option>
          <option value="Greenland">Greenland</option>
          <option value="Grenada">Grenada</option>
          <option value="Guadeloupe">Guadeloupe</option>
          <option value="Guam">Guam</option>
          <option value="Guatemala">Guatemala</option>
          <option value="Guinea">Guinea</option>
          <option value="Guinea-Bissau">Guinea-Bissau</option>
          <option value="Guyana">Guyana</option>
          <option value="Haiti">Haiti</option>
          <option value="Heard and McDonald Islands">
            Heard and Mc Donald Islands
          </option>
          <option value="Holy See">Holy See (Vatican City State)</option>
          <option value="Honduras">Honduras</option>
          <option value="Hong Kong">Hong Kong</option>
          <option value="Hungary">Hungary</option>
          <option value="Iceland">Iceland</option>
          <option value="India">India</option>
          <option value="Indonesia">Indonesia</option>
          <option value="Iran">Iran (Islamic Republic of)</option>
          <option value="Iraq">Iraq</option>
          <option value="Ireland">Ireland</option>
          <option value="Israel">Israel</option>
          <option value="Italy">Italy</option>
          <option value="Jamaica">Jamaica</option>
          <option value="Japan">Japan</option>
          <option value="Jordan">Jordan</option>
          <option value="Kazakhstan">Kazakhstan</option>
          <option value="Kenya">Kenya</option>
          <option value="Kiribati">Kiribati</option>
          <option value="Democratic People's Republic of Korea">
            Korea, Democratic People's Republic of
          </option>
          <option value="Korea">Korea, Republic of</option>
          <option value="Kuwait">Kuwait</option>
          <option value="Kyrgyzstan">Kyrgyzstan</option>
          <option value="Lao">Lao People's Democratic Republic</option>
          <option value="Latvia">Latvia</option>
          <option value="Lebanon" selected>
            Lebanon
          </option>
          <option value="Lesotho">Lesotho</option>
          <option value="Liberia">Liberia</option>
          <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
          <option value="Liechtenstein">Liechtenstein</option>
          <option value="Lithuania">Lithuania</option>
          <option value="Luxembourg">Luxembourg</option>
          <option value="Macau">Macau</option>
          <option value="Macedonia">
            Macedonia, The Former Yugoslav Republic of
          </option>
          <option value="Madagascar">Madagascar</option>
          <option value="Malawi">Malawi</option>
          <option value="Malaysia">Malaysia</option>
          <option value="Maldives">Maldives</option>
          <option value="Mali">Mali</option>
          <option value="Malta">Malta</option>
          <option value="Marshall Islands">Marshall Islands</option>
          <option value="Martinique">Martinique</option>
          <option value="Mauritania">Mauritania</option>
          <option value="Mauritius">Mauritius</option>
          <option value="Mayotte">Mayotte</option>
          <option value="Mexico">Mexico</option>
          <option value="Micronesia">Micronesia, Federated States of</option>
          <option value="Moldova">Moldova, Republic of</option>
          <option value="Monaco">Monaco</option>
          <option value="Mongolia">Mongolia</option>
          <option value="Montserrat">Montserrat</option>
          <option value="Morocco">Morocco</option>
          <option value="Mozambique">Mozambique</option>
          <option value="Myanmar">Myanmar</option>
          <option value="Namibia">Namibia</option>
          <option value="Nauru">Nauru</option>
          <option value="Nepal">Nepal</option>
          <option value="Netherlands">Netherlands</option>
          <option value="Netherlands Antilles">Netherlands Antilles</option>
          <option value="New Caledonia">New Caledonia</option>
          <option value="New Zealand">New Zealand</option>
          <option value="Nicaragua">Nicaragua</option>
          <option value="Niger">Niger</option>
          <option value="Nigeria">Nigeria</option>
          <option value="Niue">Niue</option>
          <option value="Norfolk Island">Norfolk Island</option>
          <option value="Northern Mariana Islands">
            Northern Mariana Islands
          </option>
          <option value="Norway">Norway</option>
          <option value="Oman">Oman</option>
          <option value="Pakistan">Pakistan</option>
          <option value="Palau">Palau</option>
          <option value="Panama">Panama</option>
          <option value="Papua New Guinea">Papua New Guinea</option>
          <option value="Paraguay">Paraguay</option>
          <option value="Peru">Peru</option>
          <option value="Philippines">Philippines</option>
          <option value="Pitcairn">Pitcairn</option>
          <option value="Poland">Poland</option>
          <option value="Portugal">Portugal</option>
          <option value="Puerto Rico">Puerto Rico</option>
          <option value="Qatar">Qatar</option>
          <option value="Reunion">Reunion</option>
          <option value="Romania">Romania</option>
          <option value="Russia">Russian Federation</option>
          <option value="Rwanda">Rwanda</option>
          <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
          <option value="Saint LUCIA">Saint LUCIA</option>
          <option value="Saint Vincent">
            Saint Vincent and the Grenadines
          </option>
          <option value="Samoa">Samoa</option>
          <option value="San Marino">San Marino</option>
          <option value="Sao Tome and Principe">Sao Tome and Principe</option>
          <option value="Saudi Arabia">Saudi Arabia</option>
          <option value="Senegal">Senegal</option>
          <option value="Seychelles">Seychelles</option>
          <option value="Sierra">Sierra Leone</option>
          <option value="Singapore">Singapore</option>
          <option value="Slovakia">Slovakia (Slovak Republic)</option>
          <option value="Slovenia">Slovenia</option>
          <option value="Solomon Islands">Solomon Islands</option>
          <option value="Somalia">Somalia</option>
          <option value="South Africa">South Africa</option>
          <option value="South Georgia">
            South Georgia and the South Sandwich Islands
          </option>
          <option value="Span">Spain</option>
          <option value="SriLanka">Sri Lanka</option>
          <option value="St. Helena">St. Helena</option>
          <option value="St. Pierre and Miguelon">
            St. Pierre and Miquelon
          </option>
          <option value="Sudan">Sudan</option>
          <option value="Suriname">Suriname</option>
          <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
          <option value="Swaziland">Swaziland</option>
          <option value="Sweden">Sweden</option>
          <option value="Switzerland">Switzerland</option>
          <option value="Syria">Syrian Arab Republic</option>
          <option value="Taiwan">Taiwan, Province of China</option>
          <option value="Tajikistan">Tajikistan</option>
          <option value="Tanzania">Tanzania, United Republic of</option>
          <option value="Thailand">Thailand</option>
          <option value="Togo">Togo</option>
          <option value="Tokelau">Tokelau</option>
          <option value="Tonga">Tonga</option>
          <option value="Trinidad and Tobago">Trinidad and Tobago</option>
          <option value="Tunisia">Tunisia</option>
          <option value="Turkey">Turkey</option>
          <option value="Turkmenistan">Turkmenistan</option>
          <option value="Turks and Caicos">Turks and Caicos Islands</option>
          <option value="Tuvalu">Tuvalu</option>
          <option value="Uganda">Uganda</option>
          <option value="Ukraine">Ukraine</option>
          <option value="United Arab Emirates">United Arab Emirates</option>
          <option value="United Kingdom">United Kingdom</option>
          <option value="United States">United States</option>
          <option value="United States Minor Outlying Islands">
            United States Minor Outlying Islands
          </option>
          <option value="Uruguay">Uruguay</option>
          <option value="Uzbekistan">Uzbekistan</option>
          <option value="Vanuatu">Vanuatu</option>
          <option value="Venezuela">Venezuela</option>
          <option value="Vietnam">Viet Nam</option>
          <option value="Virgin Islands (British)">
            Virgin Islands (British)
          </option>
          <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
          <option value="Wallis and Futana Islands">
            Wallis and Futuna Islands
          </option>
          <option value="Western Sahara">Western Sahara</option>
          <option value="Yemen">Yemen</option>
          <option value="Serbia">Serbia</option>
          <option value="Zambia">Zambia</option>
          <option value="Zimbabwe">Zimbabwe</option>
        </select>
      )}
      {apply && <label className="form-label required">Message</label>}
      {apply && (
        <textarea
          id="w3review"
          name="w3review"
          className="form-control"
          rows="4"
          cols="50"
          onChange={handleTextArea}
        ></textarea>
      )}
      {!apply && (
        <Select
          title={"Current Opening"}
          name={"opening"}
          options={user.currentOpening}
          value={user.currentOpening}
          placeholder={"Current Opening"}
          handleChange={handleInput}
        />
      )}
      <div className="form-btn">
        <RoundedButton
          type={"submit"}
          styles={{ padding: "0.5rem 2rem" }}
          onPress={() => {
            // validateAll();
            handleFormSubmit();
          }}
        >
          SUBMIT
        </RoundedButton>
      </div>
    </form>
  );
}

// const buttonStyle = {
//   margin: "10px 10px 10px 10px",
// };

export default FormContainer;

// import React from "react";
// import RoundedButton from "../Buttons/RoundedButton";
// import Select from "./Select";

// const validationRules = {
//   required: (val) => val !== null && val !== undefined && val !== "",
//   phone: (phone) => {
//     const re = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
//     return re.test(String(phone));
//   },
//   email: (email) => {
//     const re =
//       /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//     return re.test(String(email).toLowerCase());
//   },
// };

// class FormContainer extends React.Component {
//   constructor() {
//     super();

//     this.formValidationRules = {
//       firstName: [
//         { rule: validationRules.required, message: "First name is required" },
//       ],
//       lastName: [
//         { rule: validationRules.required, message: "Last name is required" },
//       ],
//       phone: [
//         { rule: validationRules.phone, message: "Phone number is invalid" },
//       ],
//       email: [
//         { rule: validationRules.required, message: "Email is required" },
//         { rule: validationRules.email, message: "Email is invalid" },
//       ],
//     };

//     this.fields = ["firstName", "lastName", "phone", "email"];

//     this.state = {
//       signupForm: { isValid: false },
//       firstName: { value: "", isTouched: false, isValid: false, errors: [] },
//       lastName: { value: "", isTouched: false, isValid: false, errors: [] },
//       phone: { value: "234", isTouched: true, isValid: false, errors: [] },
//       email: { value: "", isTouched: false, isValid: false, errors: [] },
//       country: { value: "", isTouched: false, isValid: false, errors: [] },
//       message: { value: "", isTouched: false, isValid: false, errors: [] },
//       currentOpening: {
//         value: [
//           "Business Development Head",
//           "Tender Executive",
//           "Operation and Procurement Manager",
//           "Machine Learning",
//         ],
//         isTouched: false,
//         isValid: false,
//         errors: [],
//       },
//     };
//   }

//   handleFieldChange = (e) => {
//     let newState = { ...this.state };
//     newState[e.target.name].value = e.target.value;
//     this.validateForm(newState);
//   };

//   handleSetTouched = (e) => {
//     let field = { ...this.state[e.target.name], isTouched: true };
//     this.setState({ [e.target.name]: { ...field } });
//   };

//   getClassName = (fieldName) => {
//     const field = this.state[fieldName];
//     return field.isTouched && !field.isValid ? "has-error" : "";
//   };

//   handleFormSubmit = (e) => {
//     // e.preventDefault();
//     // let userData = user;
//     let userData = "";
//     fetch(
//       "https://qtmbm6vyr6.execute-api.us-east-1.amazonaws.com/prod/tagbin_website_form_api_v2",
//       {
//         method: "POST",
//         body: JSON.stringify(userData),
//         headers: {
//           Accept: "",
//           "Content-Type": "application/json",
//         },
//       }
//     ).then((response) => {
//       response.json().then((data) => {
//         console.log("Successful" + data);
//       });
//     });
//   };

//   validateForm = (newState) => {
//     newState = newState || { ...this.state };
//     this.fields.map((fieldName) => {
//       let newField = newState[fieldName];
//       newField.errors = [];
//       newField.isValid = true;
//       this.formValidationRules[fieldName].map((vRule) => {
//         if (!vRule.rule(this.state[fieldName].value)) {
//           newField.errors.push(vRule.message);
//           newField.isValid = false;
//         }
//         newState[fieldName] = newField;
//       });
//     });
//     this.setState(newState);
//   };

//   componentWillMount() {
//     this.validateForm();
//   }

//   render() {
//     const {
//       firstName,
//       lastName,
//       phone,
//       email,
//       country,
//       message,
//       currentOpening,
//     } = this.state;
//     return (
//       <form>
//         <div className="field-group">
//           <label>First Name</label>
//           <input
//             className={
//               firstName.isTouched && !firstName.isValid ? "has-error" : ""
//             }
//             name="firstName"
//             value={firstName.value}
//             onChange={this.handleFieldChange}
//             onBlur={this.handleSetTouched}
//           />
//           {firstName.isTouched &&
//             firstName.errors.length > 0 &&
//             firstName.errors.map((err, i) => (
//               <span key={i} className="error-message">
//                 {err}
//               </span>
//             ))}
//         </div>
//         <div className="field-group">
//           <label>Last Name</label>
//           <input
//             className={
//               lastName.isTouched && !lastName.isValid ? "has-error" : ""
//             }
//             name="lastName"
//             value={this.state.lastName.value}
//             onChange={this.handleFieldChange}
//             onBlur={this.handleSetTouched}
//           />
//           {lastName.isTouched &&
//             lastName.errors.length > 0 &&
//             lastName.errors.map((err, i) => (
//               <span key={i} className="error-message">
//                 {err}
//               </span>
//             ))}
//         </div>
//         <div className="field-group">
//           <label>Phone</label>
//           <input
//             className={phone.isTouched && !phone.isValid ? "has-error" : ""}
//             name="phone"
//             value={this.state.phone.value}
//             onChange={this.handleFieldChange}
//             onBlur={this.handleSetTouched}
//           />
//           {phone.isTouched &&
//             phone.errors.length > 0 &&
//             phone.errors.map((err, i) => (
//               <span key={i} className="error-message">
//                 {err}
//               </span>
//             ))}
//         </div>
//         <div className="field-group">
//           <label>Email</label>
//           <input
//             className={email.isTouched && !email.isValid ? "has-error" : ""}
//             name="email"
//             value={this.state.email.value}
//             onChange={this.handleFieldChange}
//             onBlur={this.handleSetTouched}
//           />
//           {email.isTouched &&
//             email.errors.length > 0 &&
//             email.errors.map((err, i) => (
//               <span key={i} className="error-message">
//                 {err}
//               </span>
//             ))}
//         </div>
//         <div className="field-group">
//           {this.props.apply && <label>Select Country</label>}
//           {this.props.apply && (
//             <select onChange={this.props.optionSelectionHandler}>
//               <option value="Afghanistan">Afghanistan</option>
//               <option value="Albania">Albania</option>
//               <option value="Algeria">Algeria</option>
//               <option value="American Samoa">American Samoa</option>
//               <option value="Andorra">Andorra</option>
//               <option value="Angola">Angola</option>
//               <option value="Anguilla">Anguilla</option>
//               <option value="Antartica">Antarctica</option>
//               <option value="Antigua and Barbuda">Antigua and Barbuda</option>
//               <option value="Argentina">Argentina</option>
//               <option value="Armenia">Armenia</option>
//               <option value="Aruba">Aruba</option>
//               <option value="Australia">Australia</option>
//               <option value="Austria">Austria</option>
//               <option value="Azerbaijan">Azerbaijan</option>
//               <option value="Bahamas">Bahamas</option>
//               <option value="Bahrain">Bahrain</option>
//               <option value="Bangladesh">Bangladesh</option>
//               <option value="Barbados">Barbados</option>
//               <option value="Belarus">Belarus</option>
//               <option value="Belgium">Belgium</option>
//               <option value="Belize">Belize</option>
//               <option value="Benin">Benin</option>
//               <option value="Bermuda">Bermuda</option>
//               <option value="Bhutan">Bhutan</option>
//               <option value="Bolivia">Bolivia</option>
//               <option value="Bosnia and Herzegowina">
//                 Bosnia and Herzegowina
//               </option>
//               <option value="Botswana">Botswana</option>
//               <option value="Bouvet Island">Bouvet Island</option>
//               <option value="Brazil">Brazil</option>
//               <option value="British Indian Ocean Territory">
//                 British Indian Ocean Territory
//               </option>
//               <option value="Brunei Darussalam">Brunei Darussalam</option>
//               <option value="Bulgaria">Bulgaria</option>
//               <option value="Burkina Faso">Burkina Faso</option>
//               <option value="Burundi">Burundi</option>
//               <option value="Cambodia">Cambodia</option>
//               <option value="Cameroon">Cameroon</option>
//               <option value="Canada">Canada</option>
//               <option value="Cape Verde">Cape Verde</option>
//               <option value="Cayman Islands">Cayman Islands</option>
//               <option value="Central African Republic">
//                 Central African Republic
//               </option>
//               <option value="Chad">Chad</option>
//               <option value="Chile">Chile</option>
//               <option value="China">China</option>
//               <option value="Christmas Island">Christmas Island</option>
//               <option value="Cocos Islands">Cocos (Keeling) Islands</option>
//               <option value="Colombia">Colombia</option>
//               <option value="Comoros">Comoros</option>
//               <option value="Congo">Congo</option>
//               <option value="Congo">
//                 Congo, the Democratic Republic of the
//               </option>
//               <option value="Cook Islands">Cook Islands</option>
//               <option value="Costa Rica">Costa Rica</option>
//               <option value="Cota D'Ivoire">Cote d'Ivoire</option>
//               <option value="Croatia">Croatia (Hrvatska)</option>
//               <option value="Cuba">Cuba</option>
//               <option value="Cyprus">Cyprus</option>
//               <option value="Czech Republic">Czech Republic</option>
//               <option value="Denmark">Denmark</option>
//               <option value="Djibouti">Djibouti</option>
//               <option value="Dominica">Dominica</option>
//               <option value="Dominican Republic">Dominican Republic</option>
//               <option value="East Timor">East Timor</option>
//               <option value="Ecuador">Ecuador</option>
//               <option value="Egypt">Egypt</option>
//               <option value="El Salvador">El Salvador</option>
//               <option value="Equatorial Guinea">Equatorial Guinea</option>
//               <option value="Eritrea">Eritrea</option>
//               <option value="Estonia">Estonia</option>
//               <option value="Ethiopia">Ethiopia</option>
//               <option value="Falkland Islands">
//                 Falkland Islands (Malvinas)
//               </option>
//               <option value="Faroe Islands">Faroe Islands</option>
//               <option value="Fiji">Fiji</option>
//               <option value="Finland">Finland</option>
//               <option value="France">France</option>
//               <option value="France Metropolitan">France, Metropolitan</option>
//               <option value="French Guiana">French Guiana</option>
//               <option value="French Polynesia">French Polynesia</option>
//               <option value="French Southern Territories">
//                 French Southern Territories
//               </option>
//               <option value="Gabon">Gabon</option>
//               <option value="Gambia">Gambia</option>
//               <option value="Georgia">Georgia</option>
//               <option value="Germany">Germany</option>
//               <option value="Ghana">Ghana</option>
//               <option value="Gibraltar">Gibraltar</option>
//               <option value="Greece">Greece</option>
//               <option value="Greenland">Greenland</option>
//               <option value="Grenada">Grenada</option>
//               <option value="Guadeloupe">Guadeloupe</option>
//               <option value="Guam">Guam</option>
//               <option value="Guatemala">Guatemala</option>
//               <option value="Guinea">Guinea</option>
//               <option value="Guinea-Bissau">Guinea-Bissau</option>
//               <option value="Guyana">Guyana</option>
//               <option value="Haiti">Haiti</option>
//               <option value="Heard and McDonald Islands">
//                 Heard and Mc Donald Islands
//               </option>
//               <option value="Holy See">Holy See (Vatican City State)</option>
//               <option value="Honduras">Honduras</option>
//               <option value="Hong Kong">Hong Kong</option>
//               <option value="Hungary">Hungary</option>
//               <option value="Iceland">Iceland</option>
//               <option value="India">India</option>
//               <option value="Indonesia">Indonesia</option>
//               <option value="Iran">Iran (Islamic Republic of)</option>
//               <option value="Iraq">Iraq</option>
//               <option value="Ireland">Ireland</option>
//               <option value="Israel">Israel</option>
//               <option value="Italy">Italy</option>
//               <option value="Jamaica">Jamaica</option>
//               <option value="Japan">Japan</option>
//               <option value="Jordan">Jordan</option>
//               <option value="Kazakhstan">Kazakhstan</option>
//               <option value="Kenya">Kenya</option>
//               <option value="Kiribati">Kiribati</option>
//               <option value="Democratic People's Republic of Korea">
//                 Korea, Democratic People's Republic of
//               </option>
//               <option value="Korea">Korea, Republic of</option>
//               <option value="Kuwait">Kuwait</option>
//               <option value="Kyrgyzstan">Kyrgyzstan</option>
//               <option value="Lao">Lao People's Democratic Republic</option>
//               <option value="Latvia">Latvia</option>
//               <option value="Lebanon" selected>
//                 Lebanon
//               </option>
//               <option value="Lesotho">Lesotho</option>
//               <option value="Liberia">Liberia</option>
//               <option value="Libyan Arab Jamahiriya">
//                 Libyan Arab Jamahiriya
//               </option>
//               <option value="Liechtenstein">Liechtenstein</option>
//               <option value="Lithuania">Lithuania</option>
//               <option value="Luxembourg">Luxembourg</option>
//               <option value="Macau">Macau</option>
//               <option value="Macedonia">
//                 Macedonia, The Former Yugoslav Republic of
//               </option>
//               <option value="Madagascar">Madagascar</option>
//               <option value="Malawi">Malawi</option>
//               <option value="Malaysia">Malaysia</option>
//               <option value="Maldives">Maldives</option>
//               <option value="Mali">Mali</option>
//               <option value="Malta">Malta</option>
//               <option value="Marshall Islands">Marshall Islands</option>
//               <option value="Martinique">Martinique</option>
//               <option value="Mauritania">Mauritania</option>
//               <option value="Mauritius">Mauritius</option>
//               <option value="Mayotte">Mayotte</option>
//               <option value="Mexico">Mexico</option>
//               <option value="Micronesia">
//                 Micronesia, Federated States of
//               </option>
//               <option value="Moldova">Moldova, Republic of</option>
//               <option value="Monaco">Monaco</option>
//               <option value="Mongolia">Mongolia</option>
//               <option value="Montserrat">Montserrat</option>
//               <option value="Morocco">Morocco</option>
//               <option value="Mozambique">Mozambique</option>
//               <option value="Myanmar">Myanmar</option>
//               <option value="Namibia">Namibia</option>
//               <option value="Nauru">Nauru</option>
//               <option value="Nepal">Nepal</option>
//               <option value="Netherlands">Netherlands</option>
//               <option value="Netherlands Antilles">Netherlands Antilles</option>
//               <option value="New Caledonia">New Caledonia</option>
//               <option value="New Zealand">New Zealand</option>
//               <option value="Nicaragua">Nicaragua</option>
//               <option value="Niger">Niger</option>
//               <option value="Nigeria">Nigeria</option>
//               <option value="Niue">Niue</option>
//               <option value="Norfolk Island">Norfolk Island</option>
//               <option value="Northern Mariana Islands">
//                 Northern Mariana Islands
//               </option>
//               <option value="Norway">Norway</option>
//               <option value="Oman">Oman</option>
//               <option value="Pakistan">Pakistan</option>
//               <option value="Palau">Palau</option>
//               <option value="Panama">Panama</option>
//               <option value="Papua New Guinea">Papua New Guinea</option>
//               <option value="Paraguay">Paraguay</option>
//               <option value="Peru">Peru</option>
//               <option value="Philippines">Philippines</option>
//               <option value="Pitcairn">Pitcairn</option>
//               <option value="Poland">Poland</option>
//               <option value="Portugal">Portugal</option>
//               <option value="Puerto Rico">Puerto Rico</option>
//               <option value="Qatar">Qatar</option>
//               <option value="Reunion">Reunion</option>
//               <option value="Romania">Romania</option>
//               <option value="Russia">Russian Federation</option>
//               <option value="Rwanda">Rwanda</option>
//               <option value="Saint Kitts and Nevis">
//                 Saint Kitts and Nevis
//               </option>
//               <option value="Saint LUCIA">Saint LUCIA</option>
//               <option value="Saint Vincent">
//                 Saint Vincent and the Grenadines
//               </option>
//               <option value="Samoa">Samoa</option>
//               <option value="San Marino">San Marino</option>
//               <option value="Sao Tome and Principe">
//                 Sao Tome and Principe
//               </option>
//               <option value="Saudi Arabia">Saudi Arabia</option>
//               <option value="Senegal">Senegal</option>
//               <option value="Seychelles">Seychelles</option>
//               <option value="Sierra">Sierra Leone</option>
//               <option value="Singapore">Singapore</option>
//               <option value="Slovakia">Slovakia (Slovak Republic)</option>
//               <option value="Slovenia">Slovenia</option>
//               <option value="Solomon Islands">Solomon Islands</option>
//               <option value="Somalia">Somalia</option>
//               <option value="South Africa">South Africa</option>
//               <option value="South Georgia">
//                 South Georgia and the South Sandwich Islands
//               </option>
//               <option value="Span">Spain</option>
//               <option value="SriLanka">Sri Lanka</option>
//               <option value="St. Helena">St. Helena</option>
//               <option value="St. Pierre and Miguelon">
//                 St. Pierre and Miquelon
//               </option>
//               <option value="Sudan">Sudan</option>
//               <option value="Suriname">Suriname</option>
//               <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
//               <option value="Swaziland">Swaziland</option>
//               <option value="Sweden">Sweden</option>
//               <option value="Switzerland">Switzerland</option>
//               <option value="Syria">Syrian Arab Republic</option>
//               <option value="Taiwan">Taiwan, Province of China</option>
//               <option value="Tajikistan">Tajikistan</option>
//               <option value="Tanzania">Tanzania, United Republic of</option>
//               <option value="Thailand">Thailand</option>
//               <option value="Togo">Togo</option>
//               <option value="Tokelau">Tokelau</option>
//               <option value="Tonga">Tonga</option>
//               <option value="Trinidad and Tobago">Trinidad and Tobago</option>
//               <option value="Tunisia">Tunisia</option>
//               <option value="Turkey">Turkey</option>
//               <option value="Turkmenistan">Turkmenistan</option>
//               <option value="Turks and Caicos">Turks and Caicos Islands</option>
//               <option value="Tuvalu">Tuvalu</option>
//               <option value="Uganda">Uganda</option>
//               <option value="Ukraine">Ukraine</option>
//               <option value="United Arab Emirates">United Arab Emirates</option>
//               <option value="United Kingdom">United Kingdom</option>
//               <option value="United States">United States</option>
//               <option value="United States Minor Outlying Islands">
//                 United States Minor Outlying Islands
//               </option>
//               <option value="Uruguay">Uruguay</option>
//               <option value="Uzbekistan">Uzbekistan</option>
//               <option value="Vanuatu">Vanuatu</option>
//               <option value="Venezuela">Venezuela</option>
//               <option value="Vietnam">Viet Nam</option>
//               <option value="Virgin Islands (British)">
//                 Virgin Islands (British)
//               </option>
//               <option value="Virgin Islands (U.S)">
//                 Virgin Islands (U.S.)
//               </option>
//               <option value="Wallis and Futana Islands">
//                 Wallis and Futuna Islands
//               </option>
//               <option value="Western Sahara">Western Sahara</option>
//               <option value="Yemen">Yemen</option>
//               <option value="Serbia">Serbia</option>
//               <option value="Zambia">Zambia</option>
//               <option value="Zimbabwe">Zimbabwe</option>
//             </select>
//           )}
//         </div>
//         {this.props.apply && (
//           <label className="form-label required">Message</label>
//         )}
//         {this.props.apply && (
//           <textarea
//             id="w3review"
//             name="w3review"
//             className="form-control"
//             rows="4"
//             cols="50"
//             onChange={this.props.handleTextArea}
//           ></textarea>
//         )}
//         {/* {!this.props.apply && (
//           <Select
//             title={"Current Opening"}
//             name={"opening"}
//             options={this.state.currentOpening}
//             value={this.state.currentOpening}
//             placeholder={"Current Opening"}
//             handleChange={this.props.handleInput}
//           />
//         )} */}
//         <div className="form-btn">
//           <RoundedButton
//             styles={{ padding: "0.5rem 2rem" }}
//             onPress={() => {
//               this.handleFormSubmit();
//             }}
//           >
//             SUBMIT
//           </RoundedButton>
//         </div>
//       </form>
//     );
//   }
// }

// export default FormContainer;
