import { useEffect, useState } from "react";

import { Nav, Navbar, NavDropdown, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ModalShow from "./ModalShow";
import "./Header.css";
import RoundButton from "./Buttons/RoundedButton";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const option = localStorage.getItem("option");
  const [expanded, setExpanded] = useState(false);
  const [showServices, setShowServices] = useState(false);
  const [showIndustries, setShowIndustries] = useState(false);
  const [page, setPage] = useState("Home");

  const [optionSelected, setOptionSelected] = useState(
    option === null ? "Home" : option
  );
  const [show, setShow] = useState(false);
  function applyFunctionHandler() {
    setShow(!show);
  }

  const showDropdownServices = (e) => {
    setShowServices(!showServices);
  };
  const hideDropdownServices = (e) => {
    setShowServices(false);
  };
  const showDropdownIndustries = (e) => {
    setShowIndustries(!showIndustries);
  };
  const hideDropdownIndustries = (e) => {
    setShowIndustries(false);
  };

  const optionSelectedHandler = (option) => {
    localStorage.setItem("option", option);
    setOptionSelected(option);
    setPage(option === "/" ? "Home" : option);
  };
  const handler = (path) => {
    setExpanded(!expanded);
    navigate(path);
  };

  useEffect(() => {
    document.title = page;
  }, [page]);

  return (
    <>
      {console.log(optionSelected, "RENDERRRR")}
      <Navbar className="header" expand="lg" expanded={expanded}>
        <Container>
          <Navbar.Brand href="/" className="brand">
            <img src="../assets/images/logo/logo.png" alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(expanded ? false : "expanded")}
          >
            <i class="fa-solid fa-bars"></i>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                onClick={() => {
                  handler("/");
                  optionSelectedHandler("Home");
                }}
                className={`header-item nav-link-ltr ${
                  optionSelected === "Home" && "header-item-selected"
                }`}
              >
                HOME
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  handler("/about");
                  optionSelectedHandler("About Us");
                }}
                className={`header-item nav-link-ltr ${
                  optionSelected === "About Us" && "header-item-selected"
                }`}
              >
                ABOUT US
              </Nav.Link>

              <Nav.Link
                className={`header-item nav-link-ltr ${
                  optionSelected === "Projects" && "header-item-selected"
                }`}
                onClick={() => {
                  handler("/projects");
                  optionSelectedHandler("Projects");
                }}
              >
                PROJECTS
              </Nav.Link>

              <Nav.Link
                className={`header-item nav-link-ltr ${
                  optionSelected === "Contact" && "header-item-selected"
                }`}
                onClick={() => {
                  handler("/contact");
                  optionSelectedHandler("Contact");
                }}
              >
                CONTACT
              </Nav.Link>
              <Nav.Link
                className={`header-item nav-link-ltr ${
                  optionSelected === "Team" && "header-item-selected"
                }`}
                onClick={() => {
                  handler("/team");
                  optionSelectedHandler("Team");
                }}
              >
                TEAM
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div className="get-in-touch">
            <RoundButton
              styles={{
                opacity: "0",
                fontSize: "13px",
                padding: "0 12px",
                fontWeight: "500",
                height: "33px",
                lineHeight: "33px",
                textAlign: "center",
              }}
            >
              Get in touch
            </RoundButton>
          </div>
          {/* <div className="get-in-touch">
            <RoundButton
              styles={{
                fontSize: "13px",
                padding: "0 12px",
                fontWeight: "500",
                height: "33px",
                lineHeight: "33px",
                textAlign: "center",
              }}
              onPress={applyFunctionHandler}
            >
              Get in touch
            </RoundButton> */}
          {/* <ModalShow
              show={show}
              setShow={applyFunctionHandler}
              apply={false}
            />
          </div> */}
        </Container>
      </Navbar>
    </>
  );
}
export default Header;
