import React, { Suspense, useState, useEffect } from "react";

import Header from "./Components/Header";
import "./index.css";
import Footer from "./Components/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SplashScreen from "./Components/SplashScreen";
import ScrollToTop from "./Screens/ScrollToTop";

const Home = React.lazy(() => import("./Screens/Home/Home"));
const AboutUs = React.lazy(() => import("./Screens/AboutUs/AboutUs"));
const Contact = React.lazy(() => import("./Screens/Contact/Contact"));
const Blogs = React.lazy(() => import("./Screens/Blogs/Blogs"));
const Projects = React.lazy(() => import("./Screens/Projects/Projects"));
const Careers = React.lazy(() => import("./Screens/Careers/Careers"));
const GuestPost = React.lazy(() => import("./Screens/GuestPost/GuestPost"));
const News = React.lazy(() => import("./Screens/News/News"));
const Team = React.lazy(() => import("./Screens/Team/Team"));
const SingleProject = React.lazy(() =>
  import("./Screens/Projects/SingleProject")
);
const PrivacyPolicy = React.lazy(() => import("./Screens/Terms/PrivacyPolicy"));
const Terms = React.lazy(() => import("./Screens/Terms/Terms"));
const Disclaimer = React.lazy(() => import("./Screens/Terms/Disclaimer"));
function App() {
  const videoPlayed = localStorage.getItem("videoPlayed");
  const [videoEnded, setVideoEnded] = useState(
    videoPlayed == null ? false : videoPlayed
  );
  function videoStatusHandler() {
    setVideoEnded(true);
    localStorage.setItem("videoPlayed", true);
    console.log("video finished");
  }
  // return !videoEnded ? (
  //   <SplashScreen videoStatusHandler={videoStatusHandler} />
  // ) : (

  return (
    <div>
      <Router>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <Home />
              </Suspense>
            }
          ></Route>
          <Route
            path="/about"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <AboutUs />
              </Suspense>
            }
          ></Route>
          <Route
            path="/contact"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <Contact />
              </Suspense>
            }
          ></Route>
          <Route
            path="/blogs"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>.
                  </div>
                }
              >
                <Blogs />
              </Suspense>
            }
          ></Route>
          <Route
            path="/projects"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <Projects />
              </Suspense>
            }
          ></Route>
          <Route
            path="/careers"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <Careers />
              </Suspense>
            }
          ></Route>
          <Route
            path="/guestPost"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <GuestPost />
              </Suspense>
            }
          ></Route>
          <Route
            path="/news"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <News />
              </Suspense>
            }
          ></Route>
          <Route
            path="/team"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>.
                  </div>
                }
              >
                <Team />
              </Suspense>
            }
          ></Route>
          <Route
            path="/privacypolicy"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <PrivacyPolicy />
              </Suspense>
            }
          ></Route>
          <Route
            path="/terms"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>.
                  </div>
                }
              >
                <Terms />
              </Suspense>
            }
          ></Route>
          <Route
            path="/disclaimer"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <Disclaimer />
              </Suspense>
            }
          ></Route>
          <Route
            path="/projects/:project"
            element={
              <Suspense
                fallback={
                  <div className="center-body">
                    <h1>Loading...</h1>
                  </div>
                }
              >
                <SingleProject />
              </Suspense>
            }
          ></Route>
        </Routes>
        <Footer />
      </Router>
    </div>
  );
  // );
}

export default App;
