import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Components/Footer.css";
function Footer() {
  const navigate = useNavigate();
  const [page, selectPage] = useState("");
  useEffect(() => {
    console.log("page", page);
    document.title = page;
  }, [page]);
  return (
    <footer className="footer-section">
      <div className="container">
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-50">
              <div className="footer-widget">
                <div className="footer-logo">
                  <a href="/">
                    <img
                      src="../assets/images/logo/logo.png"
                      style={{ color: "white" }}
                      class="img-fluid"
                      alt="logo"
                    />
                  </a>
                </div>
                <div className="footer-text">
                  <p>
                    Tagbin is an experience-driven technology service company
                    started by IIT Roorkee Alumni for creating a digital
                    experience for government and various industries.
                  </p>
                </div>
                <div className="footer-social-icon">
                  <a href="https://www.linkedin.com/company/tagbin/">
                    <i className="fab fa-linkedin linkedin-bg"></i>
                  </a>
                  <a href="https://www.facebook.com/Tagbin">
                    <i className="fab fa-facebook-f facebook-bg"></i>
                  </a>
                  <a href="https://twitter.com/tagbin">
                    <i className="fab fa-twitter twitter-bg"></i>
                  </a>
                  <a href="https://www.youtube.com/@TAGBIN_futureinfocus">
                    <i className="fab fa-youtube youtube-bg"></i>
                  </a>
                  <a href=" https://www.instagram.com/tagbin_futureinfocus/">
                    <i className="fab fa-instagram instagram-bg"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Quick Links</h3>
                </div>
                <ul>
                  <li
                    onClick={() => {
                      selectPage("Careers");
                      navigate("/careers");
                    }}
                  >
                    Careers
                  </li>
                  <li
                    onClick={() => {
                      selectPage("News And Media");
                      navigate("/news");
                    }}
                  >
                    News And Media
                  </li>
                  <li
                    onClick={() => {
                      selectPage("Team");
                      navigate("/team");
                    }}
                  >
                    <a>Team</a>
                  </li>
                  <li
                    onClick={() => {
                      selectPage("About Us");
                      navigate("/about");
                    }}
                  >
                    <a>About Us</a>
                  </li>
                  <li
                    onClick={() => {
                      selectPage("Guest Post");
                      navigate("/guestPost");
                    }}
                  >
                    <a>Guest Post</a>
                  </li>
                  <li
                    onClick={() => {
                      selectPage("Contact");
                      navigate("/contact");
                    }}
                  >
                    <a>Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Contact Us</h3>
                </div>
                <div className="footer-text mb-25">
                  <div className="address-grid">
                    <div className="grid-address-item">Business:</div>
                    <a href="tel:+918178112682">+91-8178112682</a>
                    <div className="grid-address-item">Admin/HR :</div>
                    <a href="tel:+911244116025">0124-4116025</a>
                    <div className="grid-address-item">E-mail :</div>
                    <a href="mailto:info@tagbin.in">info@tagbin.in</a>
                    <div className="grid-address-item">Headquaters :</div>
                    <div className="grid-address-item">
                      117, LGF, World Trade Centre,Babar Road, Connaught Place,
                      New Delhi-110001
                    </div>
                    <div className="grid-address-item">Haryana :</div>
                    <div className="grid-address-item">
                      Plot No. 150 , 7th FloorUdyog Vihar Phase 1, Sector 20,
                      Gurugram, Haryana - 122008
                    </div>
                    <div className="grid-address-item">Mumbai :</div>
                    <div className="grid-address-item">
                      G-10, Zakaria,Marol Maroshi Road, Marol, Andheri (E)City,
                      Mumbai - 400059
                    </div>
                    <div className="grid-address-item">Dubai :</div>
                    <div className="grid-address-item">
                      B 501, Silicon OasisHQ Bldg, Dubai Silicon Oasis,
                      Dubai,United Arab Emirates P.O. Box: 341381
                    </div>
                    <div className="grid-address-item">Singapore :</div>
                    <div className="grid-address-item">
                      31, Simei Street 3,#08-16 My Manhattan-529902 Singapore
                    </div>
                    <div className="grid-address-item">Ahmadabad :</div>
                    <div className="grid-address-item">
                      Seven Work Spaces,CG Road, Ahmadabad
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="copyright-text">
              <p>
                Copyright &copy; 2022
                <span
                  onClick={() => {
                    selectPage("Home");
                    navigate("/");
                  }}
                >
                  Tagbin
                </span>
                <ul className="footer-menu">
                  <li
                    onClick={() => {
                      selectPage("Terms Of Use");
                      navigate("/terms");
                    }}
                  >
                    <a>Terms of Use</a>
                  </li>
                  <span className="divider-footer"> </span>
                  <li
                    onClick={() => {
                      selectPage("Disclaimer");
                      navigate("/disclaimer");
                    }}
                  >
                    <a>Disclaimer</a>
                  </li>
                  <span className="divider-footer"> </span>

                  <li
                    onClick={() => {
                      selectPage("Privacy Policy");
                      navigate("/privacypolicy");
                    }}
                  >
                    <a>Privacy Policy</a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
