import React from "react";

function Input({
  title,
  name,
  inputType,
  value,
  handleChange,
  placeholder,
  validateOne,
  validateVal,
}) {
  //console.log(props.value);
  return (
    <div className="form-group">
      <label className="form-label required">{title}</label>
      <input
        className="form-control"
        id={name}
        name={name}
        type={inputType}
        value={value}
        onChange={handleChange}
        onInput={handleChange}
        placeholder={placeholder}
        onBlur={validateOne}
        required
      />
      <div>{validateVal}</div>
    </div>
  );
}

export default Input;
